<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>预售券</el-breadcrumb-item>
            <el-breadcrumb-item>预售券列表</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>名称：</label>
                  <el-input class="width-220" v-model="mainTitleQ" placeholder="预售券名称"  clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>时间：</label>
                  <date-packer  :datePacker="dateRange" @setDatePacker="getDatePacker"></date-packer>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <el-row class="table-box">
            <!-- 表格 -->
            <div class="table-head-btn">
               <el-button-group class="m-right-10">
                  <el-button :class="statusQ === '' ? 'bg-gradient' : ''" @click="changeStatus('')" ><span>全部</span></el-button>
                  <el-button :class="statusQ === 'NOT_STARTED' ? 'bg-gradient' : ''" @click="changeStatus('NOT_STARTED')" ><span>未开始</span></el-button>
                  <el-button :class="statusQ === 'PROCESSING' ? 'bg-gradient' : ''" @click="changeStatus('PROCESSING')" ><span>进行中</span></el-button>
                  <el-button :class="statusQ === 'OVER' ? 'bg-gradient' : ''" @click="changeStatus('OVER')" ><span>已结束</span></el-button>
                  <el-dropdown @command="handleCommand">
                     <el-button  :type="this.statusQ === 'TO_BE_REVIEWED' ||
                                                this.statusQ === 'OFF_THE_SHELF' ||
                                                this.statusQ === 'SUSPEND' ? 'primary' : ''">
                        {{ statusQ | filterStatusQ }}<i class="el-icon-arrow-down el-icon--right"></i>
                     </el-button>
                     <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="TO_BE_REVIEWED">待审核</el-dropdown-item>
                        <el-dropdown-item command="OFF_THE_SHELF">已下架</el-dropdown-item>
                        <el-dropdown-item command="SUSPEND">已暂停</el-dropdown-item>
                     </el-dropdown-menu>
                  </el-dropdown>
               </el-button-group>
               <el-button class="bg-gradient" type="primary"  @click="handleAdd()">添加预售券</el-button>
            </div>
            <el-table
               ref="mainTable"
               :data="tableData"
               style="width: 100%"
               :stripe="true"
               border fit
               v-loading="loading"
               >
               <el-table-column label="序号" width="60">
               <template slot-scope="scope">{{scope.$index+1}}</template>
               </el-table-column>
               <el-table-column prop="id" label="业务编号" width="170"></el-table-column>
               <el-table-column label="预售券名称" width="150">
                  <template slot-scope="scope">
                     <span style="color: red; text-decoration: underline; cursor: pointer">{{scope.row.mainTitle}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="状态" min-width="100">
               <template slot-scope="scope">
                  {{scope.row.status | filterStatus}}
               </template>
               </el-table-column>
               <el-table-column prop="presellPrice" label="预售价" min-width="80">
               <template slot-scope="scope">
                  {{scope.row.presellPrice/100}}
               </template>
               </el-table-column>
               <el-table-column prop="marketPrice" label="商品价" min-width="80">
               <template slot-scope="scope">
                  {{scope.row.marketPrice/100}}
               </template>
               </el-table-column>
               <el-table-column prop="issueCount" label="发行量" min-width="80"></el-table-column>
               <el-table-column   label="下单量" min-width="80">
                  <template slot-scope="scope">
                     <span>{{scope.row.receiveNum || 0}}</span>
                  </template>
               </el-table-column>
               <el-table-column  label="使用量" min-width="80">
                  <template slot-scope="scope">
                     <span>{{scope.row.consumeNum || 0}}</span>
                  </template>
               </el-table-column>
               <el-table-column prop="limitQuantity" label="限购量" min-width="80"></el-table-column>
               <el-table-column prop="validDate" label="有效期" width="100">
                  <template slot-scope="scope">
                     <div v-if="scope.row.validType == 'INTERVAL_DATE'" >{{scope.row.validDate}}<p>至</p>{{scope.row.expiraDate}}</div>
                     <span v-else>{{scope.row.validDate}}</span>
                  </template>

               </el-table-column>
               <el-table-column prop="activityBeginTime" label="开始时间" width="160"></el-table-column>
               <el-table-column prop="activityEndTime" label="结束时间" width="160"></el-table-column>
               <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="100" fixed="right">
                  <template slot-scope="scope">
                     <el-dropdown @command="handleCommand1" trigger="click" style="margin-left: 10px">
                        <el-button class="el-dropdown-link" type="text" >
                           <el-link type="primary">审核</el-link>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                           <el-dropdown-item :command="[scope.row, 'detail']">详情</el-dropdown-item>
                           <el-dropdown-item v-if="scope.row.status == 'TO_BE_REVIEWED'  " :command="[scope.row, 'pass']">通过</el-dropdown-item>
                           <el-dropdown-item v-if="scope.row.status == 'TO_BE_REVIEWED' " :command="[scope.row, 'reject']">驳回</el-dropdown-item>
                        </el-dropdown-menu>
                     </el-dropdown>
                     <el-dropdown @command="handleCommand2" trigger="click" style="margin-left: 10px">
                        <el-button class="el-dropdown-link" type="text" >
                           <el-link type="primary">操作</el-link>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                           <el-dropdown-item :command="[scope.row, 'detail']">详情</el-dropdown-item>
                           <el-dropdown-item   :command="[scope.row, 'edit']">编辑</el-dropdown-item>
<!--                           <el-dropdown-item  :command="[scope.row, 'copy']">复制</el-dropdown-item>-->
                           <el-dropdown-item   :command="[scope.row, 'remove']">删除</el-dropdown-item>
                           <el-dropdown-item    :command="[scope.row, 'distribution']">分配</el-dropdown-item>
                           <el-dropdown-item  :command="[scope.row, 'PROCESSING']"    >暂停投放</el-dropdown-item>
                           <el-dropdown-item   :command="[scope.row, 'OFF_THE_SHELF']"    >停止投放</el-dropdown-item>
                           <el-dropdown-item   :command="[scope.row, 'SUSPEND']" >恢复投放</el-dropdown-item>

<!--                          <el-dropdown-item v-if="scope.row.status == 'TURN_DOWN' || scope.row.status == 'TO_BE_REVIEWED' "  :command="[scope.row, 'edit']">编辑</el-dropdown-item>-->
<!--                          &lt;!&ndash;                           <el-dropdown-item  :command="[scope.row, 'copy']">复制</el-dropdown-item>&ndash;&gt;-->
<!--                          <el-dropdown-item v-if="scope.row.status == 'TURN_DOWN' || scope.row.status == 'TO_BE_REVIEWED' || scope.row.status == 'NOT_STARTED' || scope.row.status == 'OFF_THE_SHELF' "  :command="[scope.row, 'remove']">删除</el-dropdown-item>-->
<!--                          <el-dropdown-item v-if="scope.row.status == 'NOT_STARTED' && scope.row.status == 'PROCESSING' "   :command="[scope.row, 'distribution']">分配</el-dropdown-item>-->
<!--                          <el-dropdown-item v-if="scope.row.status == 'NOT_STARTED' || scope.row.status == 'PROCESSING' " :command="[scope.row, 'PROCESSING']"    >暂停投放</el-dropdown-item>-->
<!--                          <el-dropdown-item v-if="scope.row.status == 'NOT_STARTED' || scope.row.status == 'PROCESSING' || scope.row.status == 'SUSPEND' "  :command="[scope.row, 'OFF_THE_SHELF']"    >停止投放</el-dropdown-item>-->
<!--                          <el-dropdown-item v-if="scope.row.status == 'SUSPEND' "  :command="[scope.row, 'SUSPEND']" >恢复投放</el-dropdown-item>-->
                        </el-dropdown-menu>
                     </el-dropdown>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分配渠道商 -->
            <el-dialog
               title="渠道商"
               :visible.sync="visibleDis"
               width="30%">
               <el-row>
                  <el-col class="table-box" :span="11">
                     <div class="top-text"><span>未选渠道</span></div>
                     <el-table
                        :data="unselectedData"
                        border
                        height="252"
                        @selection-change="handleSelectionChange"
                        :stripe="true"
                        >
                        <el-table-column type="selection"></el-table-column>
                        <el-table-column prop="name" label="渠道名称"></el-table-column>
                     </el-table>
                  </el-col>
                  <el-col class="table-box-arrow" style="text-align: center" :span="2">
                     <p @click="saveDistribution"><i class="el-icon-d-arrow-right"></i></p>
                  </el-col>
                  <el-col class="table-box" :span="11">
                     <div class="top-text"><span>已选渠道</span></div>
                     <el-table
                        :data="selectedData"
                        border
                        height="252"
                        :stripe="true"
                        >
                        <el-table-column prop="name" label="渠道名称"></el-table-column>
                        <el-table-column :label="$t('msg.remove')" fixed="right">
                           <template slot-scope="scope">
                              <el-button @click="handleRemoves(scope.row)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                 <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                              </el-button>
                           </template>
                        </el-table-column>
                     </el-table>
                  </el-col>
               </el-row>
            </el-dialog>
            <!-- 预购 -->
            <el-form label-width="105px" class="popup-box">
               <el-dialog title="预购券" :visible.sync="isShow" width="720px">
                  <el-row class="tit">
                     <h6>{{ mainTitle }}</h6>
                     <p>{{ subTitle }}</p>
                  </el-row>
                  <el-row class="right-price">预售价<span>{{ presellPrice/100 }}</span>元</el-row>
                  <el-row class="table-box">
                     <p>预售券类型：套餐</p>
                     <el-col style="display: flex">
                        <el-row v-for="item in popData" :key="item.id">
                        <img :src="item.images" alt="" class="width-175">
                        <p>{{item.remark}}</p>
                        </el-row>
                     </el-col>
                  </el-row>
                  <el-row class="text-list">
                     <el-row>
                        <el-col :span="12">
                           <p><span class="width-100">商品总价</span>{{ marketPrice/100 }} 元</p>
                        </el-col>
                        <el-col :span="12">
                           <p><span class="width-100">发行量</span>{{ issueCount }}</p>
                        </el-col>
                     </el-row>
                     <el-row>
                        <el-col :span="12">
                           <p><span class="width-100">活动时间</span>{{ activityBeginTime }}  {{ activityEndTime }}</p>
                        </el-col>
                        <el-col :span="12">
                           <p><span class="width-100">单用户限购数</span>{{ limitQuantity }}</p>
                        </el-col>
                     </el-row>
                     <el-row>
                        <el-col :span="12">
                           <p><span class="width-100">积分兑换</span>{{ isIntegral | isIntegral }}</p>
                        </el-col>
                        <el-col :span="12">
                           <p><span class="width-100">过期退款方式</span>{{ refundType | refundType }}</p>
                        </el-col>
                     </el-row>
                     <el-row>
                        <el-col :span="12">
                        <p><span class="width-100">有效期</span><span v-show="day == false">{{ expiraDays }} 天</span><span v-show="day == true">{{ validDate }}</span></p>
                        </el-col>
                        <el-col :span="12">
                           <p><span class="width-100">使用方式</span>{{ useType | useType }}</p>
                        </el-col>
                     </el-row>
                     <el-row>
                        <el-col :span="12">
                           <p>
                              <span class="width-100">标签</span>
                              <el-tag >{{ tag }}</el-tag>
                           </p>
                        </el-col>
                     </el-row>
                  <el-row>
                     <el-col :span="12">
                        <p>
                        <span class="name width-100">预售券简介</span>{{ remark }}</p>
                     </el-col>
                  </el-row>
                  </el-row>
               </el-dialog>
            </el-form>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit"  :current-page="page" v-if="pageshow" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-row>
   </div>
</template>

<script>
import { urlObj } from "@/api/interface";
import { distributor , preSale } from "@/api/interface/business";
import { dateFactory } from "@/common/js/common"; // 引入子组件(分页)
import { mapState } from 'vuex'
export default {
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.hotelCode = this.hotelInfo.hotelCode
               this.getPreSalePage()
            }
         }
      }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
   data(){
      return{
         tableData: [],         // 表格数据
         loading:true,
         total: 0,              // 总数
         limit: 0,              // 每页数
         page: 1,               // 当前页
         statusQ: '',           // 状态
         mainTitleQ: '',        // 名称
         dateRange: [],         // 时间区间
         visibleDis: false,     // 分配弹窗
         isShow: false,         // 详情弹窗
         hotelCode: '',         // 酒店编码
         hotelId: '',           // 酒店ID
         popData: [],           // 套餐类型
         unselectedData: [],    // 未选渠道数据
         selectedData: [],      // 已选渠道列表
         distributorIds: [],    // 未选中渠道ID
         /* 详情 */
         activityBeginTime: '', // 开始时间
         activityEndTime: '',   // 结束时间
         limitQuantity: '',     // 限购数
         presellPrice: '',      // 预售价
         marketPrice: '',       // 商品总价
         tag: '',               // 标签
         expiraDays: '',        // 有效期
         couponId: '',          // 预授券编码
         coverPath: '',         // 封面图片路径
         pageshow:true,
         validDate: '',
         mainTitle:'',
         subTitle:'',
         issueCount:'',
         isIntegral:'',
         refundType:'',
         useType:'',
         day:'',
         remark:'',
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   mounted() {
      this.hotelId = this.hotelInfo.id
      this.hotelCode = this.hotelInfo.hotelCode
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getPreSalePage()
   },
   methods: {
      // 预售卷列表
      getPreSalePage(){
         const url = preSale.preSalePage
         let param = {
            page: this.page,
            limit: this.limit,
            hotelId: this.hotelId,
            beginTime: this.dateRange[0],
            endTime: this.dateRange[1],
            status: this.statusQ,
            mainTitle: this.mainTitleQ
         }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
               this.loading = false
            }
         })
      },
      // 改变优惠券状态
      changeStatus(val){
         this.page = 1
         this.statusQ = val
         this.getPreSalePage()
      },
      // 优惠券其他状态
      handleCommand(val){
         this.page = 1
         this.statusQ = val
         this.getPreSalePage()
      },
      // 获取查询活动时间
      getDatePacker(val){
         this.dateRange = val
      },
      // 查询预售券
      handleQuery(bool){
         if (bool) {
            this.pageshow = false
            this.page = 1
            this.$nextTick(() => {
               this.pageshow = true
            })
            this.getPreSalePage()
         } else {
            this.statusQ = ''
            this.mainTitleQ = ''
            this.dateRange = []
         }
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getPreSalePage()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getPreSalePage()
      },
      // 新增预售券
      handleAdd(){
         this.$router.push('/pre_sale_adds')
      },
      //修改预售券状态
      emitpresale(id,status){
         let state
         if (status == 'PROCESSING'){
            state = 'SUSPEND'
         }
         if(status == 'SUSPEND'){
            state = 'PROCESSING'
         }
         if(status == 'OFF_THE_SHELF'){
            state = 'OFF_THE_SHELF'
         }
         let param = {
            id:id,
            status:state
         }
         const url = preSale.examinecouponPresell;
         this.$axios.post(url, param).then((res) => {
                  if (res.status == 200) {
                     this.getPreSalePage()
                     this.$message({
                        message: "修改成功!",
                        type: "success",
                     });
                  }
         });
      },
      // 操作
      handleCommand2(command){
         switch (command[1]) {
            case 'detail':
               this.handleDetail(command)
               break
            case 'edit':
               this.handleEdit(command)
               break
            case 'copy':
               this.handleCopy(JSON.stringify(command))
               break
            case 'remove':
               this.handleDel(command)
               break
            case 'distribution':
               this.handleDistribution(command)
               break
            case 'SUSPEND':
               this.emitpresale(command[0].id,'SUSPEND')
               break
            case  'PROCESSING':
               this.emitpresale(command[0].id,'PROCESSING')
               break
            case  'OFF_THE_SHELF':
               this.emitpresale(command[0].id,'OFF_THE_SHELF')
               break
         }
      },
      // 处理分配
      handleDistribution(command){
         this.visibleDis = true
         let channelObj = command[0]
         let coverPathObj = JSON.parse(channelObj.coverPath)
         this.coverPath = window.getResourcesHost().replace('http', 'https') + coverPathObj.location+'/' + coverPathObj.formatName
         this.couponId = channelObj.id
         this.categoryName = this.hotelCode + '/' + coverPathObj.location
         this.getSelectedChannel()
      },
      // 获取已选渠道列表
      getSelectedChannel(){
         const url = distributor.getBindList
         const param = { hotelId: this.hotelId, couponId: this.couponId }
         this.$axios.post(url, param).then(res => {
            this.selectedData = res.records
            this.getUnselectedChannel()
         })
      },
      // 获取未选渠道列表
      getUnselectedChannel(){
         const url = distributor.distributor
         const param = { hotelId:this.hotelId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               let unselectedData = res.records
               // 使用逆循环 删除数组元素
               for (let i = unselectedData.length - 1;i >= 0;i --){
                  for (let j = 0;j <= this.selectedData.length - 1;j ++){
                     // 找出符合删除条件的元素
                     if (unselectedData[i]){
                        if (unselectedData[i].id === this.selectedData[j].distributorId){
                           unselectedData.splice(i, 1)
                        }
                     }
                  }
               }
               this.unselectedData = unselectedData
            }
         })
      },
      // 未选渠道 获取未选渠道列表选中元素
      handleSelectionChange(val){
         this.distributorIds = val.map(item => {
            return item.id
         })
      },
      // 确认分配
      saveDistribution(){
         const url = distributor.bindChannel
         const param = {
            distributorIds: this.distributorIds.join(),
            hotelId: this.hotelId,
            couponId: this.couponId,
            coverPath: this.coverPath,
            categoryName: this.categoryName
         }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.$message({
                  message: '分配成功!',
                  type: 'success'
               });
               this.getSelectedChannel()
            }
         })
      },

      //删除已分配渠道
      handleRemoves(row){
         const url = distributor.deleteChannel
         let param = { categoryName: row.categoryName, id: row.id }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               this.$message({
                  message: '渠道删除成功!',
                  type: 'success'
               })
               this.getSelectedChannel()
            }
         })
      },

      // 删除预售券
      handleDel(command){
         if (command[0].status == 'PROCESSING' ){
            this.$message.error('该预售正参与营销活动，暂不能删除')
            return
         }
         this.$confirm('您确定要删除当前预售券活动吗？', this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            const url = preSale.deletecouponPresell+command[0].id
            let param = {}
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: '删除成功！',
                     type: 'success'
                  })
                  this.getPreSalePage()
               }
            })
         })
      },
      // 查看预售券详情
      handleDetail(command){
         this.isShow = true
         let data = command[0]
         this.popData = JSON.parse(data.content || data.coverPath)
         this.popData instanceof Array ? "" : this.popData = [this.popData]
         this.popData.forEach(item=>{
            item.images = window.getResourcesHost()+item.location+'/'+item.formatName
         })
         this.mainTitle = data.mainTitle
         this.subTitle = data.subTitle
         this.issueCount = data.issueCount
         this.isIntegral = data.isIntegral
         this.refundType = data.refundType
         this.useType = data.useType
         this.activityBeginTime = data.activityBeginTime
         this.activityEndTime = data.activityEndTime
         this.limitQuantity = data.limitQuantity
         this.presellPrice = data.presellPrice
         this.marketPrice = data.marketPrice
         this.tag = data.tag
         this.remark = data.remark
         this.validDate = data.validDate
         this.expiraDays = data.expiraDays
         if (data.expiraDays == undefined){
            this.day = true
         }
      },
      // 编辑预售券
      handleEdit(command){
         command = JSON.stringify(command)
         this.$router.push({path: '/pre_sale_adds', query: {command  }})
      },
      // 复制预售券
      handleCopy(command){
         this.$router.push({path: '/pre_sale_adds', query: { command }})
      },
      // 审核
      handleCommand1(command){
         switch (command[1]) {
            case 'detail':
               this.handleDetail(command)
               break
            case 'pass':
               this.handlePass(command)
               break
            case 'reject':
               this.handleReject(command)
               break
         }
      },
      // 通过审核
      handlePass(command){
         let newtime  =  new Date();
         let oDate1 = new Date(newtime);
         let oDate2 = new Date(command[0].activityEndTime);
         if(oDate1.getTime() > oDate2.getTime()){
            this.$message.error('所设活动时间已结束,请编辑后重试')
         } else {
            this.$confirm('您确定要通过当前预售券方案吗？', this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = preSale.examinecouponPresell
               let param = { id: command[0].id, status: 'NOT_STARTED' }
               this.$axios.post(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: '活动通过！',
                        type: 'success'
                     })
                     this.getPreSalePage()
                  }
               })
            })
         }




      },
      // 驳回审核
      handleReject(command) {
         this.$confirm('您确定要驳回当前预售券方案吗？', this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            const url = preSale.examinecouponPresell;
            let param = { id: command[0].id, status: 'TURN_DOWN' }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: '活动驳回成功！',
                     type: 'success'
                  })
                  this.getPreSalePage()
               }
            })
         })
      }
   },
   filters:{
      filterStatus(val){
         switch (val){
            case 'NOT_STARTED':
               return val = '未开始'
            case 'PROCESSING':
               return val = '进行中'
            case 'OVER':
               return val = '已结束'
            case 'TO_BE_REVIEWED':
               return val = '待审核'
            case 'OFF_THE_SHELF':
               return val = '已下架'
            case 'SUSPEND':
               return val = '已暂停'
            case 'TURN_DOWN':
               return val = "已驳回"
         }
      },
      filterStatusQ(val){
         switch (val){
            case 'TO_BE_REVIEWED':
               return val = '待审核'
            case 'OFF_THE_SHELF':
               return val = '已下架'
            case 'SUSPEND':
               return val = '已暂停'
            default:
               return val = '其他'
         }
      },
      isIntegral(val){
         if (val == 'NO'){
            return '不可积分兑换'
         }
         if (val == 'YES'){
            return '可用积分兑换'
         }
      },
      refundType(val){
         if (val == 'AUTOMATIC'){
            return '自动退款'
         }
         if (val == 'MANUAL'){
            return '手动退款'
         }
         if (val == 'REFUSE'){
            return '不可退款'
         }
      },
      useType(val){
         if (val == 'ONLINE'){
            return '线上预约'
         }
         if (val == 'OFFLINE'){
            return '线下使用'
         }
         if (val == 'ONLINE_OFFLINE'){
            return '线上线下都可使用'
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.popup-box{
   .tit{
      text-align: center;
      h6{ font-size: 18px; margin-bottom: 10px }
      p{ font-size: 16px }
   }
   .right-price{ text-align: right; span{ font-size: 26px; color: red } }
   .table-box{ width: 70%; margin: 0 auto }
   .text-list{
      width: 80%!important; margin: 20px auto 0; line-height: 30px; color: #666666;
      span{ display: inline-block; text-align: right; margin-right: 15px; color: #222222 }
   }
}
.el-icon-d-arrow-right{
  margin-top: 200%;
  font-size: 1.8rem;
  cursor: pointer;
}
.top-header{
   .el-dropdown{
      border-left: solid 1px #DCDFE6;
   }
}
</style>
